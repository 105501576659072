import { isValidAddress, isValidContact } from 'lib/validation/contact'
import { getByCountryCode, patchByCountryCode } from 'lib/localStorage'
import { ORDER_SESSION } from 'configuration/global.configuration'

import type { OrderInformation } from 'types/checkout'
import type { CoreOptions } from '@adyen/adyen-web/dist/types/core/types'
import { AdyenCheckout, ApplePay, type ApplePayButtonType, PayPal, type PayPalConfiguration } from '@adyen/adyen-web'
import type { Environment } from 'types/environment'
import type * as Brink from 'types/vendors/brink'
import { getAdyenClientKey, getAdyenEnvironment, onAdyenSessionError } from '~/lib/adyen'

// import { CartState } from './cart'

// function checkIfOrderIsBlocked(items: CartState['items'] | undefined) {
//   if (!items) return false

//   const blockedItemSkus: string[][] = [[]]

//   const itemSkus = items.map(item => item.parentSku)

//   const shouldBlockOrder = blockedItemSkus.some(blockedItemSku =>
//     itemSkus.every(itemSku => blockedItemSku.includes(itemSku))
//   )

//   return shouldBlockOrder
// }

export const useOrderStore = defineStore('OrderStore', () => {
  const orderLoading = ref(false)
  const session = ref<OrderInformation | undefined>(undefined)
  const adyenSupportFunctions = useAdyenSupportFunctions()

  const context = useNuxtApp()
  const checkoutStore = useCheckoutStore()
  const storefrontStore = useStorefrontStore()
  const {
    public: { adyenEnvironment, adyenClientKey, adyenClientKeyUs },
  } = useRuntimeConfig()

  const createLocalStore = () => {
    let state: any = null

    return {
      setState(value: object) {
        state = { ...state, ...value }
      },
      getState() {
        return state
      },
    }
  }
  const store = createLocalStore()

  const setSession = (value: OrderInformation | undefined) => {
    patchByCountryCode(storefrontStore.currentMarketCountryCode, ORDER_SESSION, value)
    session.value = value
  }

  const setSessionFromLocalStorage = () => {
    session.value = getByCountryCode<OrderInformation>(
      storefrontStore.currentMarketCountryCode,
      ORDER_SESSION,
    )
  }

  function updateSessionPaymentMethod(paymentMethod: string) {
    if (!session.value)
      return

    setSession({
      ...session.value,
      order: {
        ...session.value.order,
        payment: {
          ...session.value.order.payment,
          method: paymentMethod,
        },
      },
    })
  }

  const getAndValidateCheckoutInformation = () => {
    const {
      contact,
      address: { shipping: shippingAddress, useShippingForBilling },
    } = checkoutStore.information
    let { billing: billingAddress } = checkoutStore.information.address

    if (useShippingForBilling)
      billingAddress = shippingAddress

    const { language: languageCode } = storefrontStore.currentMarket

    if (!isValidAddress(shippingAddress))
      throw new EvalError('Invalid or incomplete shipping address')
    if (!isValidAddress(billingAddress))
      throw new EvalError('Invalid or incomplete billing address')
    if (!isValidContact(contact))
      throw new EvalError('Invalid or incomplete contact object')

    return { shippingAddress, billingAddress, languageCode, contact }
  }

  const createOrderSession = async (returnUrl: string): Promise<OrderInformation> => {
    // Activate during fraud attacks to stop the attacker from creating orders
    // const cartStore = useCartStore()
    // const orderIsBlocked = checkIfOrderIsBlocked(cartStore.state.items)

    // if (orderIsBlocked) {
    //   return Promise.reject(new Error())
    // }

    orderLoading.value = true

    try {
      const { shippingAddress, billingAddress, languageCode, contact }
        = getAndValidateCheckoutInformation()

      const { order, session } = await context.$payments.createPaymentSession({
        shippingAddress,
        billingAddress,
        languageCode,
        returnUrl,
        contact,
      })

      const data = {
        order,
        session,
      }

      setSession(data)

      return data
    }
    catch (e) {
      // Most likely there's no cart session
      // if (e.code === UNAUTHORIZED) {
      //   return Promise.reject(e)
      // }

      console.error('Failed to create an Adyen Payment Session', e)
      return Promise.reject(e)
    }
    finally {
      orderLoading.value = false
    }
  }

  /**
   * Retrieve the session and order info from the session storage
   */
  const restoreOrCreateSession = (returnUrl: string): Promise<OrderInformation> => {
    if (session.value)
      return Promise.resolve(session.value)

    return createOrderSession(returnUrl)
  }

  const confirmOrder = async (signature: string, orderId: string) => {
    try {
      const order = await context.$payments.confirmPaymentSession(signature, orderId)

      return order
    }
    catch (error) {
      return Promise.reject(error)
    }
  }

  const createAdvanceFlow = async (returnUrl: string, amount: { currency: string, value: number }, emit: any) => {
    const { storefrontCode } = storefrontStore.current
    const { locale, countryCode } = storefrontStore.currentMarket
    const channel = 'Web'
    const languageCode = adyenSupportFunctions.getAdyenLanguage(locale.html)

    // 1. Get payment methods
    const paymentMethodsResponse = await context.$payments.getPaymentMethods({ countryCode, languageCode, channel })

    // 2. Create Config
    const clientKey = getAdyenClientKey(storefrontCode, {
      us: adyenClientKeyUs,
      default: adyenClientKey,
    })

    const getEnvironment = () => getAdyenEnvironment(storefrontStore.currentMarketCountryCode, adyenEnvironment)

    const checkoutConfiguration: CoreOptions = {
      clientKey,
      environment: getEnvironment(),
      amount,
      locale: adyenSupportFunctions.getAdyenLanguage(locale.html),
      countryCode,
      paymentMethodsResponse,
      // merchantAccount,
      onSubmit: async (state: any, component: any, actions: any) => {
        try {
          console.log('call on submit')
          // TO DO: How should this be updated...
          const orderBody: Brink.AdyenCartToAdyenOrderBody = {
            shippingAddress: {
              country: countryCode,
              city: 'Solna',
              phone: '0761111111',
              streetAddress: 'Lövgatan',
              houseNumberOrName: '55',
              familyName: 'Tran Luu',
              postalCode: '16932',
              givenName: 'Nicole',
              region: 'Stockholm',
            },
            billingAddress: {
              country: countryCode,
              city: 'Solna',
              phone: '0761111111',
              streetAddress: 'Lövgatan',
              houseNumberOrName: '55',
              familyName: 'Tran Luu',
              postalCode: '16932',
              givenName: 'Nicole',
              region: 'Stockholm',
            },
            email: 'user@example.com',
          }

          await context.$payments.createCartOrders(orderBody).then(async (orderResponse) => {
            const body: Brink.AdyenPaymentRequestBody = {
              redirect: {
                canceled: location.href,
                default: location.href,
                success: location.href,
                error: location.href,
              },
              storePaymentMethod: true,
              orderId: orderResponse.id,
              origin: location.href,
              paymentMethod: state.data.paymentMethod,
              shopperLocale: locale.html,
              browserInfo: {},
              shopperReference: 'test',
            }

            return { payment: await context.$payments.requestPayments(body), order: orderResponse }
          }).then((result) => {
            store.setState({ pspReference: result.payment.paymentResult.pspReference, order: result.order })

            if (!result.payment.paymentResult.resultCode) {
              actions.reject()
              return
            }

            const {
              resultCode,
              action,
              order,
              donationToken,
            } = result.payment.paymentResult

            actions.resolve({
              resultCode,
              action,
              order,
              donationToken,
            })
          }).catch((error) => {
            actions.reject()
            return error
          })
        }
        catch (error) {
          console.error('onSubmit', error)
          actions.reject()
        }
      },
      onAdditionalDetails: async () => {
      // NOT NEEDED FOR APPLE PAY BUT FOR PAYPAL?
        console.log('on additional details')
      },
      onPaymentCompleted: async (result: Adyen.PaymentSessionResponse, component: any) => {
        console.log('done')
        // if (orderSignature.value) {
        //   try {
        //     const { id } = await orderStore.confirmOrder(orderSignature.value, order.id)
        //     return adyenSupportFunctions.handleServerResponse(result, component, id)
        //   }
        //   catch (error) {
        //     emit('paymentError')
        //     console.error(error)
        //     checkoutStore.loading = false
        //   }
        // }
        // else {
        const order = store.getState().order
        return adyenSupportFunctions.handleServerResponse(result, component, order.id)
        // }
      },
      onPaymentFailed: async (result: Adyen.PaymentSessionResponse) => {
        emit('paymentError')
        if (result) {
          console.error(result.resultCode)
        }
        checkoutStore.loading = false
      },
      onError: onAdyenSessionError,
    }

    const checkout = await AdyenCheckout(checkoutConfiguration)
    return checkout
  }

  const setUpApplePayExpress = (checkout: any) => {
    const createApplePayAmountHelper = () => {
      let applePayTotal: any = null

      function convertFloatAmountToAdyenAmount(totalPrice: string): number {
        if (totalPrice.includes('.')) {
          return Number(totalPrice.replace('.', ''))
        }
        return Number(`${totalPrice}00`)
      }

      return {
        getFinalAdyenAmount() {
          return convertFloatAmountToAdyenAmount(applePayTotal.amount)
        },
        getApplePayTotal() {
          return applePayTotal
        },
        setApplePayTotal(newTotal: any) {
          applePayTotal = newTotal
        },
      }
    }

    const ApplePayAmountHelper = createApplePayAmountHelper()

    const createLineItems = (shippingMethod?: any): any => {
      const cartStore = useCartStore()
      const { state: cartState } = storeToRefs(cartStore)
      const lineItems = []

      for (const item of cartState.value.items) {
        lineItems.push({
          label: item.name,
          amount: item.price.final,
          type: 'final' as const,
        })
      }

      lineItems.push({
        label: `Delivery: ${shippingMethod.label}`,
        amount: shippingMethod.amount,
        type: 'final' as const,
      })
      return lineItems
    }

    const createApplePayTotal = (lineItems: any) => {
      let totalPrice = 0.0
      lineItems.forEach((item: any) => (totalPrice += Number.parseFloat(item.amount)))
      return {
        label: 'MYSTORE, INC.', // TO DO: UPDATE
        amount: totalPrice.toString(),
      }
    }

    const applePayConfiguration = {
      isExpress: true,
      buttonType: 'plain' as ApplePayButtonType,
      onAuthorized: (data: any, actions: any) => {
        actions.resolve()
      },

      onShippingContactSelected: async (resolve: any, reject: any, event: any) => {
        const { countryCode } = event.shippingContact
        let update: any = {}

        // // NEED THIS?
        // if (countryCode === 'BR') {
        //   update = {
        //     newTotal: ApplePayAmountHelper.getApplePayTotal(),
        //     // errors: [new ApplePayError('shippingContactInvalid', 'countryCode', 'Cannot ship to the selected address')],
        //   }
        //   resolve(update)
        //   return
        // }

        const newShippingMethods = await adyenSupportFunctions.createShippingMethodListApplePay(countryCode)

        const newLineItems = createLineItems(newShippingMethods[0])
        const newTotal = createApplePayTotal(newLineItems)
        console.log(newShippingMethods)
        console.log(newLineItems)
        ApplePayAmountHelper.setApplePayTotal(newTotal)

        update = {
          newTotal,
          newLineItems,
          newShippingMethods,
        }
        resolve(update)
      },

      onShippingMethodSelected: (resolve: any, reject: any, event: any) => {
        console.log('event', event)
        const { shippingMethod } = event
        const newLineItems = createLineItems(shippingMethod)
        const newTotal = createApplePayTotal(newLineItems)

        const update: any = {
          newTotal,
          newLineItems,
        }

        ApplePayAmountHelper.setApplePayTotal(newTotal)
        resolve(update)
      },

      requiredBillingContactFields: ['postalAddress'],
      requiredShippingContactFields: ['postalAddress', 'name', 'phoneticName', 'phone', 'email'],
    }

    const applePay = new ApplePay(checkout, applePayConfiguration)
    return applePay
  }

  const setUpPaypalExpress = (checkout: any) => {
    const paypalConfiguration: PayPalConfiguration = {
      isExpress: true,
      userAction: 'continue',
      blockPayPalVenmoButton: true,
      blockPayPalCreditButton: true,
      blockPayPalPayLaterButton: true,

      onShippingAddressChange: async (data: any, actions: any, component: any) => {
        const selectedShippingOption = data.selectedShippingOption
        const deliveryMethods = await adyenSupportFunctions.createShippingMethodListPayPal(data.shippingAddress.countryCode, component.props.amount.currency, selectedShippingOption ? selectedShippingOption.id : undefined)
        const deliveryAmount = selectedShippingOption ? selectedShippingOption.amount.value : deliveryMethods[0].amount.value
        store.setState({ SHOPPER_SHIPPING_COUNTRY_CODE: data.shippingAddress.countryCode })

        const body = {
          pspReference: store.getState().pspReference,
          paymentData: component.paymentData.paymentData ? component.paymentData.paymentData : component.paymentData,
          amount: { value: component.props.amount.value + deliveryAmount, currency: component.props.amount.currency },
          deliveryMethods,
        }

        const newPaymentData = await $fetch('/api/adyen-session-payment/update-order', { method: 'POST', body })

        component.updatePaymentData(newPaymentData)
      },

      onShippingOptionsChange: async (data: any, actions: any, component: any) => {
        const selectedShippingOption = data.selectedShippingOption
        const deliveryMethods = await adyenSupportFunctions.createShippingMethodListPayPal(store.getState().SHOPPER_SHIPPING_COUNTRY_CODE, component.props.amount.currency, selectedShippingOption.id)
        const deliveryAmount = selectedShippingOption.amount.value * 100

        const body = {
          pspReference: store.getState().pspReference,
          paymentData: component.paymentData.paymentData ? component.paymentData.paymentData : component.paymentData,
          amount: { value: component.props.amount.value + deliveryAmount, currency: component.props.amount.currency },
          deliveryMethods,
        }

        const newPaymentData = await $fetch('/api/adyen-session-payment/update-order', { method: 'POST', body })
        component.updatePaymentData(newPaymentData)
      },

      onAuthorized(data, actions) {
        console.log('onAuthorized', data)
        actions.resolve()
      },
    }

    const paypal = new PayPal(checkout, paypalConfiguration)
    return paypal
  }

  return {
    orderLoading,
    session,

    setSession,
    setSessionFromLocalStorage,
    updateSessionPaymentMethod,
    restoreOrCreateSession,
    createOrderSession,
    confirmOrder,
    createAdvanceFlow,
    setUpApplePayExpress,
    setUpPaypalExpress,

  }
})
